import {
    Box,
    Flex,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    IconButton,
    SimpleGrid,
    Image,
  } from "@chakra-ui/react";
  import {
    FaFacebook,
    FaYoutube,
    FaTwitter,
    FaLinkedin,
    FaAngleRight,
  } from "react-icons/fa";
  import {
   
  
    FaInstagram,
    FaPhoneAlt
  } from "react-icons/fa";
  import {MdEmail} from "react-icons/md"
  import { Link } from "react-router-dom";
  function Footer() {
    return (
      <Box as="footer" pt="50px" bg="white" color={"black"}>  
      <Box border={"1px solid gray"} w="80%" m="auto"  mt="50px"></Box>
        <Flex w="90%" m="auto" direction={{base:"column",md:"row",lg:"row"}} gap={"50px"} >
        <Box m="auto" w="350px"   textAlign={"start"}>
            <Image src={`/logo512.png`}/>
  
           
            <Text mt="20px"  fontSize={"15"} fontWeight={600} align={"justify"}>
            We are one of the leading RO service provider company in Delhi NCR. The reason why we have been able to recognize ourselves in such a positive influence among customers is a remarkable quality of service.


            </Text>
          </Box>




          <Box w="150px" mt="50px"  textAlign={"start"}>
            <Heading
              as="h3"
              
              borderBottom={"2px solid #f28524"}
              fontSize="xl"
              mb={4}
            >
              Useful Links
            </Heading>
            <UnorderedList listStyleType="none" pl={0}>
            <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <ListItem>Home</ListItem>
              </Flex>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <ListItem>About us</ListItem>
              </Flex>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <ListItem>catlouge</ListItem>
              </Flex>
              <Flex>
                <Text color={"gray"} mt="4px">
                  {<FaAngleRight />}
                </Text>
                <ListItem>contact</ListItem>
              </Flex>
              
            </UnorderedList>
          </Box>


          <Box  w="350px"  mt="50px" textAlign={"start"}>
            <Heading
              w="50%"
              borderBottom={"2px solid #f28524"}
              as="h3"
            
              fontSize="xl"
              mb={4}
            >
              Get In Touch
            </Heading>
            <Text>C-517/18 Hastal Road Near Sunar Market Uttam Nagar ND-110059</Text>
            <Text>Phone: +91 7428578294</Text>
            <Text>Phone: +91 7428783127</Text>
            <Text>Email: info@waterjetpurifier.com</Text>
            
           
          </Box>

        
         
  
         
        </Flex>
        <Box border={"1px solid gray"} w="80%" m="auto"  mt="50px"></Box>
        <Flex w="80%" m="auto" justifyContent={"space-between"} direction={{base:"column",md:"row",lg:"row"}}>
        <Box>
          <Heading fontSize={"30px"} textAlign={"start"} mb="30px" mt="40px">Payment methods</Heading>
          <Flex>
          <Image ml="5px" w="60px" src={`/upi.svg`}/>
          <Image ml="5px" w="60px" src={`/master-card.svg`}/>
          <Image ml="5px" w="60px" src={`/visa.svg`}/>
          <Image ml="5px" w="60px" src={`/net-banking.svg`}/>
          <Image ml="5px" w="60px" src={`/wallet.svg`}/>
          </Flex>

          
          </Box>
<Box>

<Heading textAlign={"start"} mb="30px" mt="40px" fontSize={"30px"}>Follow Us</Heading>
<Flex mt={"10px"} >


              <IconButton
                as="a"
                href="https://www.facebook.com"
                target="_blank"
                bg="#f28524"
                aria-label="Facebook"
                icon={<FaFacebook />}
                size="md"
                colorScheme="blue"
                mr={4}
              />
              <IconButton
                as="a"
                href="https://www.youtube.com"
                target="_blank"
                bg="#f28524"
                aria-label="YouTube"
                icon={<FaYoutube />}
                size="md"
                colorScheme="red"
                mr={4}
              />
              <IconButton
                as="a"
                href="https://www.youtube.com"
                target="_blank"
                bg="#f28524" 
                aria-label="YouTube"
                icon={<FaInstagram />}
                size="md"
                colorScheme="red"
                mr={4}
              />
              <IconButton
                as="a"
                bg="#f28524"
                href="https://www.twitter.com"
                target="_blank"
                aria-label="Twitter"
                icon={<FaTwitter />}
                size="md"
                colorScheme="twitter"
                mr={4}
              />
              <IconButton
                as="a"
                href="https://www.linkedin.com"
                bg="#f28524"
                target="_blank"
                aria-label="LinkedIn"
                icon={<FaLinkedin />}
                size="md"
                colorScheme="linkedin"
              />
            </Flex>
</Box>

          </Flex>

        





  
        <Box w="100%" m="auto" borderTop={"1px solid black"} mt="10px" h="50px" bg="gray.100">
          <Text fontWeight={"600"}pt="10px" align={"center"}>© Copyright 2024 Waterjet All rights reserved.</Text>
        </Box>
        <Box as="footer"  p={4} bg={"black"} color={"white"}>
   
     
    <Box mt={[4, 0]} display={"flex"} justifyContent={"center"}>
        <Link to="https://techakiras.com/">
       <Image h="70px" src={`/akiras.png`}/>
        </Link>
      </Box> 
      
  </Box>
      </Box>
    );
  }
  
  export default Footer;
  