// ModalComponent.js
import React, { useState } from 'react';
import { ChakraProvider, Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Image, Heading } from '@chakra-ui/react';
import Formdata from './Form';

const Buy = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    return (
        <ChakraProvider>
            <Box p={4}>
                <Image  borderRadius={"50%"} border={"2px solid "}  h={{base:"60px",lg:"100px"}} onClick={onOpen} src={`/Icon/hand.png`}/> 

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Heading textAlign={"center"}>BUY WAMC</Heading>
                           <Formdata/>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
};

export default Buy;
