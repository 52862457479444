import {Box,  Flex,Link,  Image, SimpleGrid, Heading, Icon, Text } from '@chakra-ui/react';





const Gallery = () => {
    const craouselData = [
        {
          url:`/Gallery/oneg.jpeg`
        },{
            url:`/Gallery/twog.jpeg`
          },
          {
            url:`/Gallery/threeg.jpeg`
          },
          {
            url:`/Gallery/fourg.jpeg`
          },
          {
            url:`/Gallery/fiveg.jpeg`
          },
          {
            url:`/Gallery/sixg.jpeg`
          },
          {
            url:`/Gallery/seveng.jpeg`
          },
          {
            url:`/Gallery/eightg.jpeg`
          },
          {
            url:`/Gallery/nineg.jpeg`
          },
          {
            url:`/Gallery/10g.jpeg`
          },
          {
            url:`/Gallery/eleveng.jpeg`
          },
          {
            url:`/Gallery/12g.jpeg`
          },{
            url:`/Gallery/th13.jpeg`
          },
          {
            url:`/Gallery/fo14.jpeg`
          }
          
    ];
    
      
  return (
    <Box >

<Box display={"flex"} justifyContent={"center"} pt="20px" pb="20px">
  <Heading>OUR CUSTOMER'S</Heading>
</Box>


           <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4} w="90%" m="auto" >
        {craouselData.map((item, index) => (
          <Box
        
            key={index}
            position="relative"
            cursor="pointer"
            
          >
            <Image src={item.url}  width="100%" height="300px" />
          </Box>
        ))}
      </SimpleGrid>  
    </Box>
  );
};

export default Gallery;



