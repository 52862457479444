import { Box, Heading } from '@chakra-ui/react';

function PdfCatalog() {
  return (
    <Box width={{base:"100%",lg:"80%"}} mx="auto">
        <Heading textAlign={"center"}  mb="30px" pt="30px">OUR CATLOUGE</Heading>
      <object width="100%" height="800" data="waterjetimage/catlog.pdf" type="application/pdf">
        <embed src="catlog.pdf" type="application/pdf" />
      </object>
    </Box>
  );
}

export default PdfCatalog;
