import {react} from "react"
import { Box, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react"



const About = () => {
    return(
        <Box>
            <Box><Image src={`/aboutwater.jpg`}/>

            
            </Box>


           <Box display={"flex"} justifyContent={"center"}> <Heading pt="100px"pb="10px">ABOUT US</Heading></Box>
            <Box fontWeight={600}  w={{base:"90%",lg:"50%"}} m="auto"><Text>Our team consists of highly trained professionals with vast experience and exposure in water and waste water industry for years.</Text></Box>




            <SimpleGrid columns={[1,2,2,2]} gap="50px" w="90%" m="auto" mb="10px">
                <Box pt="70px">
                    <Heading>Welcome To Waterjet purifiers</Heading>
                    <Text mt="20px" align={"justify"}>We are providing the best Ro Repair, Installation & AMC service with a pocket friendly budget. We know how a water purifier is important for our daily life so we are committed to our customer for a satisfied & qualitative service. Contact us today for more info. We are best, honest and reliable service experts for fast, quick, & straightforward service. Contact we are best RO Service provider in waterjet We have a great team which will help you regarding any type of water purifier installation service.</Text>
                </Box>
                <Box></Box>
            </SimpleGrid>


            <SimpleGrid columns={[1,2,2,2]} gap="50px" w="100%" m="auto" bgImage={`glas.jpg`} bgSize={"cover"}>
                <Box pt="70px" pb="100PX" ml='30px'>
                    <Heading >Why Choose     <br />Waterjet purifiers Service?</Heading>
                    <Text mt="20px" align={"justify"} fontWeight={600}>We do innovation for better health and secure future. We are the best manufacturer and seller of water purifier, and health-related products.</Text>
                    <Box textAlign={"start"} mt="30px" fontWeight={600}>
                    <Text> Fixed Rate for spare parts.</Text>
                    <Text>Educated And Expert Technician.</Text>
                    <Text> Fast service within 2 hours.</Text>
                    <Text> 30 Day Satisfaction Guarantees.</Text>
                    </Box>
                </Box>
               
            </SimpleGrid>
        </Box>
    )
}
export default About