import React from 'react';
import { Box, Flex, Text, keyframes } from '@chakra-ui/react';

const slideLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const Textb = () => {
  return (
    <Box as="section" bgColor="" color="red" fontFamily="helvetica"  overflowX="hidden">
      <Flex wrap="nowrap" minW="100%">
        <Flex className="news-message" h="60px" alignItems="center" animation={`${slideLeft} 40s linear infinite`}>
          <Text fontSize="25px" fontWeight="100" pl="0.5em" whiteSpace="nowrap">
            ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ NOTE :- Our company WATERJET has not given any Authority or permission to anyone to sell our products on any Online Selling Site like Amazon, Flipkart, etc. If you are purchasing our product on any online platform, in that case, the company is not responsible for that product
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Textb;
