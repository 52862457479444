import React from 'react';
import { Box, Flex, Image, Link } from '@chakra-ui/react';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/Footer';
import PdfCatalog from './Components/Catalog';
import { AllRoutes } from './Components/AllRoutes';
import Formdata from './Components/Form';
import ModalComponent from './Components/ModelForm';

function App() {
  return (
    <div className="App">

      <Box mb={{base:"80px",lg:"70px"}}>

        <Navbar />
      </Box>

      <AllRoutes />

      <Flex
        position="fixed"
        right="0"
        top="87%"
        transform="translateY(-50%)"
        flexDirection="column"
        alignItems="center"
        zIndex="999"
      >
        <Box mb="2">
          <Link href="tel:7428783127">
          <Image h="50px" bg="green" borderRadius="50%" src={"https://img.freepik.com/premium-vector/phone-icon-logo-vector-design-template_827767-1199.jpg?w=2000"} />
          </Link>
        </Box>
      </Flex>

      <Flex
        position="fixed"
        right="0"
        top="95%"
        transform="translateY(-50%)"
        flexDirection="column"
        alignItems="center"
        zIndex="999"
      >
        <Box mb="2">
          <Link href='https://wa.me/7428757946'>
          <Image h="50px" bg="green" borderRadius="50%" src={`whatsapp.svg`} />
          </Link>
        </Box>
      </Flex>

      <Footer />
    </div>
  );
}

export default App;
