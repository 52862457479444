import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Image,
} from '@chakra-ui/react';

const Modaltwo = () => {
  const [selectedPurifier, setSelectedPurifier] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const waterPurifiers = [
    {
      id: 1,
      brand: "Geyser (10 LTR)",
      price: "8,499/-",
      Volts : "230V",
      bodyMaterial: "ABS Plastic",
      tankMaterial: "SS Jindal",
      power: "2000 Watts",
      capacity: "10 Ltr.",
      dimensions: "330 × 325 × 480(MM)",
      warranty: { tank: "5 Yrs",
      components: "1 Yr" },
      input:"2 kv",
      cutoff:"Auto",
      preclass:"||",
      maxwaterhead:"MTS",
      imageUrl: "/photo/ro6.jpg",
    }, {
      id: 2,
      brand: "Geyser (15 LTR)",
      price: "9,999/-",
      Volts : "230V",
      bodyMaterial: "ABS Plastic",
      tankMaterial: "SS Jindal",
      power: "2000 Watts",
      capacity: "15 Ltr.",
      dimensions: "330 × 325 × 480(MM)",
      warranty: { tank: "5 Yrs",
      components: "1 Yr" },
      input:"2 kv",
      cutoff:"Auto",
      preclass:"||",
      maxwaterhead:"MTS",
      imageUrl: "/photo/ro6.jpg",
    }, {
      id: 3,
      brand: "Geyser (25 LTR)",
      price: "11,999/-",
      capacity: "25 Ltr.",
      power: "2000 Watts",
      Volts : "230V",
      bodyMaterial: "ABS Plastic",
      tankMaterial: "SS Jindal",
      dimensions: "330 × 325 × 480(MM)",
      input:"2 kv",
      cutoff:"Auto",
      warranty: { tank: "5 Yrs",
      components: "1 Yr" },
      preclass:"||",
      maxwaterhead:"MTS",
      imageUrl: "/photo/ro6.jpg",
    },
   

    
  ];

  const openModal = (id) => {
    const purifier = waterPurifiers.find((p) => p.id === id);
    setSelectedPurifier(purifier);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPurifier(null);
    setIsModalOpen(false);
  };

  return (
    <Box color="white" pb="50px">
      {/* Your video background code here */}

      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3 }} spacing={8} w={{base:"80%",md:"60%",lg:"60%" }} m="auto">
        {waterPurifiers.map((purifier) => (
         <Box
         border={"8px solid #D2EFFD"}
        boxShadow="18px"
        
         key={purifier.id}
         borderRadius="lg"
         overflow="hidden"
     
         bg="white"
         color="black"
         onClick={() => openModal(purifier.id)}
         cursor="pointer"
         transition="transform 0.2s ease-in-out"
         _hover={{ transform: "scale(1.1)" }}
       >
         <Image src={purifier.imageUrl} alt={purifier.brand} width="100%" height="auto" />
       
         <Box display={"flex"} justifyContent={"center"}>
           <Heading fontSize="xl">{"Waterjet"}</Heading>
           {/* Add more details as needed */}
         </Box>
         <Box pb="10px" display={"flex"} justifyContent={"center"}>
           <Heading fontSize="xl">{purifier.brand}</Heading>
           {/* Add more details as needed */}
         </Box>
       </Box>
       
        ))}
      </SimpleGrid>

      {/* Details Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={"orange"}>{selectedPurifier?.brand} Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src='/Waterjet Ro353_page-0001.jpg'/>
            {/* Render detailed information about the selected purifier */}
            {/* <Flex direction={{base:"column",md:"row",md:"row"}}>
            <Box w={{base:"100%",md:"50%",lg:"40%"}}>
            <Image src={selectedPurifier?.imageUrl}/>
            </Box>
            <Box w={{base:"100%",md:"50%",lg:"60%"}}>

            <Text textDecoration={"underline"} fontFamily={"inherit"} fontWeight={500} fontSize="20px">KEY FEATURES</Text>


            <Text fontWeight={500} fontSize="15px">
  Brand<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.brand}
</Text>

<Text fontWeight={500} fontSize="15px">
Capacity<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.capacity}
</Text>
<Text fontWeight={500} fontSize="15px">
  Power<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.power}
</Text>
<Text fontWeight={500} fontSize="15px">
  Body Material<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.bodyMaterial}
</Text>
<Text fontWeight={500} fontSize="15px">
  Tank Material <span style={{marginLeft:"10px",marginRight:"10px"}}>:</span>{selectedPurifier?.tankMaterial}
</Text>


<Text fontWeight={500} fontSize="15px">
  Input<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.input}
</Text>
<Text fontWeight={500} fontSize="15px">
  Cutoff<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.cutoff}
</Text>
<Text fontWeight={500} fontSize="15px">
  Pressur Class <span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.preclass}
</Text>
<Text fontWeight={500} fontSize="15px">
Max Water Head<span style={{marginLeft:"10px",marginRight:"10px"}}>:</span> {selectedPurifier?.maxwaterhead}
</Text>
<Text fontFamily={"serif"} fontWeight={600}>FOOD GRADE VIRGIN MATERIAL</Text>

 
            </Box>




            </Flex>

            <Text bg={"ORANGE"} pl="30px" fontWeight={700} fontSize="22px">Price : {selectedPurifier?.price}</Text> */}
            
            {/* Add more details as needed */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Modaltwo;
