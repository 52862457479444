import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

export const ContactForm = () => {
  const toast=useToast()
  // State to store form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });

  const handleChange = (e) => {
    // Update the form data state when input values change
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.number || !formData.email || !formData.message) {
        // Show an error toast for empty fields
        toast({
            title: 'Error',
            description: 'Please fill out all required fields.',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
        return;
    }


    try {
        const response = await axios.post('https://water-e8j1.onrender.com/send-sms', formData);


        toast({
            title: 'Message Sent',
            description: 'Your message has been sent successfully!',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        console.error('Error sending message:', error);

        toast({
            title: 'Error',
            description: 'Failed to send message. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
};

  return (
    <Box  pb="60px" pt="50px" border={"3px solid black"}>
      <Box display={"flex"} justifyContent={"center"}>
        <Heading >Send Query</Heading></Box>
      {/* form  */}
      <Box w={{ base: '90%', lg: '100%' }} m="auto" p={4}>
        <form onSubmit={handleSubmit}>
          <Flex
            w="100%"
            direction={{ base: 'column', md: 'column', lg: 'row' }}
          >

            <Box w={{base:"90%",md:"90%",lg:"40%"}} m="auto">
              <FormControl id="name" mb={4}>
                <FormLabel>Your Name</FormLabel>
                <Input
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormControl>

              <FormControl id="email" mb={4}>
                <FormLabel>Your Email</FormLabel>
                <Input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormControl>

              <FormControl id="number" mb={4}>
                <FormLabel>Your Phone</FormLabel>
                <Input
                  type="tel"
                  value={formData.number}
                  onChange={handleChange}
                />
              </FormControl>
            </Box>

            {/* Right side of the form */}
            <Box w={{base:"90%",md:"90%",lg:"40%"}} m="auto">
              <FormControl id="message" mb={4}>
                <FormLabel>Your Message</FormLabel>
                <Textarea
                  rows={6}
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </FormControl>

              <Button type="submit" colorScheme="teal">
                Send Us
              </Button>
            </Box>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};
