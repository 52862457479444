// Formdata.js

import React, { useState } from 'react';
import { ChakraProvider, Box, Input, Textarea, Button, useToast } from '@chakra-ui/react';
import axios from 'axios';

const Formdata = () => {
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: '',
    });

    const toast = useToast();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.number || !formData.email || !formData.message) {
            // Show an error toast for empty fields
            toast({
                title: 'Error',
                description: 'Please fill out all required fields.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }


        try {
            const response = await axios.post('https://water-e8j1.onrender.com/send-sms', formData);
    

            toast({
                title: 'Message Sent',
                description: 'Your message has been sent successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error sending message:', error);

            toast({
                title: 'Error',
                description: 'Failed to send message. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box p={4}>
                <form onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        mb={4}
                        required
                    />
                    <Input
                        type="text"
                        name="number"
                        placeholder="Number"
                        value={formData.number}
                        onChange={handleChange}
                        mb={4}
                        required
                    />
                    <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        mb={4}
                        required
                    />
                    <Textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        mb={4}
                        required
                    />
                    <Button type="submit" colorScheme="teal">
                        Submit
                    </Button>
                </form>
            </Box>
        </ChakraProvider>
    );
};

export default Formdata;
