import React, { useState } from 'react';
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Image,
} from '@chakra-ui/react';

const Modaldata = () => {
  const [selectedPurifier, setSelectedPurifier] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const waterPurifiers = [
    {
      id: 1,
      brand: "HYDRO",
      price: "17,999/-",
      Volts: "24V",
      STORAGECAPICITY: "10 LTR",
      capacity: "10 to 12 LTR/HR",
      inatatype: "Wall Mounting",
      waterquelity: "2000 PPM(TDS MAX)",
      dimensions: "420 X H 540 X 280(MM)",
      netweigth: "9.5 KG",
      details:"/photo/hydro.png",
      imageUrl: "/waterjetimage/Waterjet Ro5 (1).jpg",
    },
    {
      id: 2,
      brand: "EON-7",
      price: "14,499/-",
      Volts: "24V",
      STORAGECAPICITY: "13 LTR",
      capacity: "10 to 15 LTR/HR",
      inatatype: "Wall Mounting",
      waterquelity: "2000 PPM(TDS MAX)",
      dimensions: "W338 X H 467 X D344(MM)",
      netweigth: "8 KG",
      details:"/photo/e7.jpg",
      imageUrl: "/waterjetimage/Waterjet Ro4 (1).jpg",
    },
    {
      id: 3,
      brand: "ALIVE-27",
      price: "17,999/-",
      Volts: "24V",
      STORAGECAPICITY: "13 LTR",
      capacity: "10 to 15 LTR/HR",
      inatatype: "Wall Mounting",
      waterquelity: "2000 PPM(TDS MAX)",
      dimensions: "W336 X H 456 X D345(MM)",
      netweigth: "9 KG",
      details:"/photo/alive27.jpg",
      imageUrl: "/waterjetimage/Waterjet Ro3 (1).jpg",
    },
    {
      id: 4,
      brand: "OPERA+",
      price: "13,499/-",
      Volts: "24V",
      STORAGECAPICITY: "12 LTR",
      capacity: "10 to 15 LTR/HR",
      inatatype: "Wall Mounting",
      waterquelity: "2000 PPM(TDS MAX)",
      dimensions: "W420 X H 260 X D570(MM)",
      netweigth: "8 KG. APRX",
      details:"/photo/opera.jpg",
      imageUrl: "/waterjetimage/Waterjet Ro (4).jpg",
    },
    {
      id: 5,
      brand: "ZUMA XL",
      price: "13,999/-",
      Volts: "24V",
      STORAGECAPICITY: "13 LTR",
      capacity: "10 to 15 LTR/HR",
      inatatype: "Wall Mounting",
      waterquelity: "2000 PPM(TDS MAX)",
      dimensions: "W30.5 X H59.4 X L45",
      netweigth: "10 KG. APRX",
      details:"/photo/zuma.jpg",
      imageUrl: "/waterjetimage/Waterjet Ro2 (2).jpg",
    },


  ];

  const openModal = (id) => {
    const purifier = waterPurifiers.find((p) => p.id === id);
    setSelectedPurifier(purifier);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPurifier(null);
    setIsModalOpen(false);
  };

  return (
    <Box  color="white" pb="50px" pt="100px">
      {/* Your video background code here */}

      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 5 }} spacing={8} p={8}>
        {waterPurifiers.map((purifier) => (
          <Box
            border={"8px solid #D2EFFD"}
            key={purifier.id}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            bg="white"
            color="black"
            onClick={() => openModal(purifier.id)}
            cursor="pointer"
            transition="transform 0.2s ease-in-out"
            _hover={{ transform: "scale(1.1)" }}
          >
            <Image src={purifier.imageUrl} alt={purifier.brand} width="100%" height="auto" />
          </Box>

        ))}
      </SimpleGrid>

      {/* Details Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={"orange"}>{selectedPurifier?.brand} Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={selectedPurifier?.details}/>
            {/* Render detailed information about the selected purifier */}
            {/* <Flex direction={{ base: "column", md: "row", md: "row" }}>
              <Box w={{ base: "100%", md: "50%", lg: "40%" }}>
                <Image src={selectedPurifier?.imageUrl} />
              </Box>
              <Box w={{ base: "100%", md: "50%", lg: "60%" }}>
                <Text fontWeight={500} fontSize="15px">
                  Brand<span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.brand}
                </Text>
                <Text fontWeight={500} fontSize="15px">
                  Dimensions<span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.dimensions}
                </Text>
                <Text fontWeight={500} fontSize="15px">
                  Power Supply<span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.Volts}
                </Text>
                <Text fontWeight={500} fontSize="15px">
                Installation Type <span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.inatatype}
                </Text>

                
                <Text fontWeight={500} fontSize="15px">
                Storage Capacity<span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.STORAGECAPICITY}
                </Text>
                <Text fontWeight={500} fontSize="15px">
                Capacity <span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span>{selectedPurifier?.capacity}
                </Text>

                
                <Text fontWeight={500} fontSize="15px">
                Water Quality<span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.waterquelity}
                </Text>
                <Text fontWeight={500} fontSize="15px">
                Net Weight<span style={{ marginLeft: "10px", marginRight: "10px" }}>:</span> {selectedPurifier?.netweigth}
                </Text>
                
                <Text fontFamily={"serif"} fontWeight={600}>FOOD GRADE VIRGIN MATERIAL</Text>


              </Box>
            </Flex>
            <Text bg="orange" pl="30px" fontWeight={600} fontSize="22px">price: {selectedPurifier?.price}</Text> */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Modaldata;
