import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PdfCatalog from './Catalog'
import Home from './Home'
import Contact from './Contact'
import About from './About'
import Product from './Product'
import Spare from './Spare'
import Plant from './Plants'
import Gallery from './Gallery'
import Geyser from './Geyser'





export const AllRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path='/catlouge' element={<PdfCatalog/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/about' element={<About/>}/>
        <Route path='/waterpurifiers' element={<Product/>}/>
        <Route path='/spareparts' element={<Spare/>}/>
        <Route path='/plants' element={<Plant/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/geyser' element={<Geyser/>}/>

       
        
    </Routes>
  )
}