import {Box,  Flex,Link,  Image, SimpleGrid, Heading, Icon, Text } from '@chakra-ui/react';





const Plant = () => {


  return (
    <Box color={"white"} display={"flex"} justifyContent={"center"}>

<Box>
      <Image w="100%" src={`/newplant.jpg`}/>
    </Box>




         

  
   




       
    </Box>
  );
};

export default Plant;



