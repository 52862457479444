import {Box,  Flex,Link,  Image, SimpleGrid, Heading, Icon, Text } from '@chakra-ui/react';
import ModalData from './Model';
import Modaltwo from './Modaltwo';




const Geyser = () => {

//     Body Material : ABS Plastic 
// Tank Material : SS Jindal
// Power : 2000 Watts
// Capacity  : 10 Ltr. / 15 Ltr. / 25 Ltr 
// Dimensions : 330 × 325 × 480(MM) 
// // Warranty : 5 Yrs ( Tank) 1 Yr (Components)


// HYDRO - 17,999/-
// EON-7 - 16,499/-
// ALIVE-27 - 15,999/-
// OPERA+ - 15,499 /-
// ZUMA XL - 14,499/-

    const waterPurifiers = [
        {
          id: 1,
          brand: "HYDRO",
          price: "17,999/-",
          Volts : "230V",
          features: ["Reverse Osmosis", "UV Sterilization", "Smart Filter"],
          bodyMaterial: "ABS Plastic",
          tankMaterial: "SS Jindal",
          power: "2000 Watts",
          capacity: ["10 Ltr.", "15 Ltr.", "25 Ltr."],
          dimensions: "330 × 325 × 480(MM)",
          warranty: { tank: "5 Yrs",
          components: "1 Yr" },
          imageUrl: "/photo/ro5.jpg",
        },
        {
          id: 2,
          brand: "EON-7",
          price: "16,499/-",
          Volts : "230V",
          features: ["Activated Carbon Filter", "Alkaline Ionizer", "Energy Efficient"],
          bodyMaterial: "ABS Plastic",
          tankMaterial: "SS Jindal",
          power: "2000 Watts",
          capacity: ["10 Ltr.", "15 Ltr.", "25 Ltr."],
          dimensions: "330 × 325 × 480(MM)",
          warranty: { tank: "5 Yrs", components: "1 Yr" },
          imageUrl: "/photo/ro4.jpg",
        },
        {
          id: 3,
          brand: "ALIVE-27",
          Volts : "230V",
          price: "15,999/-",
          features: ["Multi-Stage Filtration", "Digital Display", "Compact Design"],
          bodyMaterial: "ABS Plastic",
          tankMaterial: "SS Jindal",
          power: "2000 Watts",
          capacity: ["10 Ltr.", "15 Ltr.", "25 Ltr."],
          dimensions: "330 × 325 × 480(MM)",
          warranty: { tank: "5 Yrs", components: "1 Yr" },
          imageUrl: "/photo/ro3.jpg",
        },
        {
            id: 4,
            brand: "OPERA+",
            Volts : "230V",
            price: "15,499/-",
            features: ["Multi-Stage Filtration", "Digital Display", "Compact Design"],
            bodyMaterial: "ABS Plastic",
            tankMaterial: "SS Jindal",
            power: "2000 Watts",
            capacity: ["10 Ltr.", "15 Ltr.", "25 Ltr."],
            dimensions: "330 × 325 × 480(MM)",
            warranty: { tank: "5 Yrs", components: "1 Yr" },
            imageUrl: "/photo/r01.png",
          },
          {
            id: 5,
            brand: "ZUMA XL",
            Volts : "230V",
            price: "14,499/-",
            features: ["Multi-Stage Filtration", "Digital Display", "Compact Design"],
            bodyMaterial: "ABS Plastic",
            tankMaterial: "SS Jindal",
            power: "2000 Watts",
            capacity: ["10 Ltr.", "15 Ltr.", "25 Ltr."],
            dimensions: "330 × 325 × 480(MM)",
            warranty: { tank: "5 Yrs", components: "1 Yr" },
            imageUrl: "/photo/ro2.jpg",
          },
    
        
      ];
      
      

  return (
    <Box pb="50px">

{/* <Box        
            mt={{base:"-40px",lg:"-130px"}}
            as="video"
            width="100%"
            height="100%"
            _hover={{ all: "none" }}
            objectFit="inherit"
            autoPlay
            loop
            muted
            onEnded={(e) => {
              e.target.currentTime = 0;
              e.target.play();
            }}
          >
            <source src={`/product.mp4`} type="video/mp4" />
          </Box> */}





          <Box display={"flex"} justifyContent={"center"} pt="50px" pb="50px"> 
          <Heading >OUR PRODUCTS</Heading>
          </Box>

         

  
   




      <Modaltwo/>
      

    </Box>
  );
};

export default Geyser;



