import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Select,
  IconButton,
  useDisclosure,
  Image,
  Text,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();

  const handleCourseChange = (event) => {
    const coursePath = event.target.value;
    navigate(coursePath);
    onToggle();

  };

  return (
    <Box position="fixed" top={0} left={0} right={0} zIndex={999}  >

      <Box  h="80px" w="100%" bg={"#D2EFFD"} color={"black"}>
      
        <Flex alignItems="center"  justifyContent="space-between">
          <Link to="/">
            <Image
              h="80px"
              ml="30px"
              src={`logo512.png`}
              alt="Logo"
            />
          </Link>
          <Flex
            display={{ base: 'none', md: 'none', lg: 'flex' }}
            justifyContent="space-between"
            w="50%"
          >
            <Flex justifyContent="space-around" gap="40px" pt="15px" fontWeight={600}>
              <Link to="/">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Home
                </Text>
              </Link>
              <Link to="/about">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  About Us
                </Text>
              </Link>

              <Link to="/contact">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Contact
                </Text>
              </Link>

              <Select
                fontWeight={600}
                w="120px"
                placeholder="Products"
                _hover={{ border: 'none' }}
                border="none"
                mt="-7px"
                onChange={handleCourseChange}
                value={location.pathname}
              >
                <option value="/waterpurifiers">Water Purifier's</option>
                <option value="/geyser">Geyser</option>
                <option value="/plants">Plant's</option>
                <option value="/spareparts">Spare Parts</option>
              </Select>


              <Link to="/catlouge">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Catlouge


                </Text>
              </Link>
              <Link to="/gallery">
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Gallery


                </Text>
              </Link>

             

             

            </Flex>
          </Flex>
          <IconButton
            icon={<HamburgerIcon />}
            mr="10px"
            color="black"
            bg="transparent"
            aria-label="Open Menu"
            display={{ base: 'block', md: 'block', lg: 'none' }}
            onClick={onToggle}
          />
        </Flex>
        <HStack
          spacing={6}
          display={{ base: isOpen ? 'flex' : 'none', md: isOpen ? 'flex' : 'none' }}
          pl="30px"
          bg="white"
          flexDirection="column"
          fontWeight={600}
          alignItems="start"
        >
          <Link to="/" onClick={onToggle}>
            <Text _hover={{ color: '#f28524', borderBottom: '2px solid #f28524' }}>Home</Text>
          </Link>
          <Link to="/about" onClick={onToggle}>
            <Text _hover={{ color: '#f28524', borderBottom: '2px solid #f28524' }}>About Us</Text>
          </Link>
          <Select
            fontWeight={600}
            ml="-15px"
            placeholder="Products"
            _hover={{ border: 'none' }}
            border="none"
            mt="-10px"
            onChange={handleCourseChange}


          >
            {/* Add your products options here */}
            <option value="/waterpurifiers">Water Purifier's</option>
            <option value="/geyser">Geyser</option>
            <option value="/plants">Plant's</option>
            <option value="/spareparts">Spare Parts</option>
           
          </Select>

          <Link to="/catlouge" onClick={onToggle}>
            <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
              Catlouge
            </Text>
          </Link>
          <Link to="/gallery" onClick={onToggle}>
                <Text _hover={{ color: '#2A86C6', borderBottom: '2px solid #2A86C6' }}>
                  Gallery


                </Text>
              </Link>

          <Link to="/contact" onClick={onToggle}>
            <Text _hover={{ color: '#f28524', borderBottom: '2px solid #f28524' }}>Contact</Text>
          </Link>

        </HStack>
      </Box>
    </Box>
  );
};

export default Navbar;