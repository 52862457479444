import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; 
import Slider from './Slider';
import { ContactForm } from './Contactform';
import Modaldata from './Model';
import Modaltwo from './Modaltwo';
import ModalComponent from './ModelForm';
import Buy from './Buyamc';
import Datea from './Date';
import Slider1 from './Slider';
import Slidertwo from './Slider1';
import Textb from './Text';



const Home = () => {
  return (<Box>
    
  
      

        

<Slider/>
       
       {/* ------------------slider-----------------  */}

       
{/* product image ------------------------   */}
<Box >
<Box display={"flex"} justifyContent={"center"} pb="10px">
<Heading    mt="20px" >OUR NEW LAUNCH</Heading>
</Box>
<Box w={{base:"90%",md:"50%",lg:"50%"}} m="auto" pb="30px">
<Text  >We are a Complete Water Consulting and Solution Enterprise. We provide complete water treatment solutions which are Cost Effective, Eco-Friendly, Client Specific</Text>
</Box>
</Box>


{/* ----------------------------all product ------------------- */}


   
    <Box display={"flex"} justifyContent={"center"}>
       <Modaltwo/>
       </Box>
    
    {/* ---------------------product cart -----------------  */}

{/* slider2------------- */}
<Slidertwo/>

{/* ----------------------------image ----------------------------  */}
{/* ----------------blue box-------------------- */}
<Box bg={"#D2EFFD"}  align={"center"} pt={{base:"30px",lg:"100PX"}} pb="60px" mb="40px">
  <Heading fontSize={{base:"25px",lg:"35px"}}>We Understand Your Needs</Heading>
  <Heading fontSize={{base:"19px",lg:"24px"}} pb={{base:"20px",lg:"60px"}}>Begin The Journey Of Good Health With WATERJET</Heading>
  <SimpleGrid columns={[3,3,3,3]}>
  <Box>  <ModalComponent/>
  <Heading fontSize={{base:"20px",lg:"30px"}}>SERVICE</Heading>
  </Box >
  <Box >  <Buy/> 
  <Heading fontSize={{base:"20px",lg:"30px"}}>BUY WAMC</Heading>
  </Box>
  <Box>  <Datea/>
  <Heading fontSize={{base:"20px",lg:"30px"}}>BOOK DEMO</Heading>
  </Box>

  </SimpleGrid>

</Box>






    <Box>
      <Image src={`/photo/allposter.jpg`}/>
    </Box>

    {/* ----------------------------image ----------------------------  */}

{/* video  */}
 
{/* <Box
            as="video"
            width="100%"
            height="100%"
            _hover={{ all: "none" }}
            objectFit="inherit"
            autoPlay
            loop
            muted
            onEnded={(e) => {
              e.target.currentTime = 0;
              e.target.play();
            }}
          >
            <source src={`/water.mp4`} type="video/mp4" />
          </Box> */}

          {/* video end -------------------  */}

{/* 
          <Box w="100%" overflow="hidden" mt="30px" mb="30px">
      <Flex
        w={{ base: '2900px', lg: '3000px' }}
        fontSize="23px"
        whiteSpace="nowrap"
        overflow="hidden"
        color="red"
        animation="moveText 15s linear infinite"
        css={{
          '@keyframes moveText': {
            '0%': {
              transform: 'translateX(100%)',
            },
            '100%': {
              transform: 'translateX(-100%)',
            },
          },
        }}
      >
        NOTE :- Our company WATERJET has not given any Authority or permission to anyone to sell our products on any Online Selling Site like Amazon, Flipkart, etc. If you are purchasing our product on any online platform, in that case, the company is not responsible for that product
      </Flex>
    </Box> */}


<Textb/>




{/* why us  */}
<Box display={"flex"} justifyContent={"center"}>
<Heading mt="10px"  >WHY CHOOSE WATERJET ?</Heading>
</Box>
<Box w={{base:"100%",lg:"50%"}} m="auto " textAlign={"center"}  mt="10px" mb="50px">
<Text>We provide best Ro services and quality installation with the use of professional equipment.</Text>
</Box>











 {/* footer---------------  */}
    <SimpleGrid columns={[1,2,2,2]} gap="40px" w="90%" m="auto" pb="30px">
            <Box>
<Flex gap={"20px"}>
<Box  >
  <Heading textAlign={"start"}>Our Mission</Heading>
  <Text align={"justify"}>Our Mission is to make India a healthy and prosperous country. We and our team are working hard to deliver the best products in the field of healthcare and home appliances.</Text>
</Box>

</Flex>
            </Box>
            <Box >
            <Flex gap={"20px"}>
<Box>
  <Heading textAlign={"start"} >Our Plan</Heading>
  <Text align={"justify"}>Our plan is to make a system where each people can get a healthy environment to live and grow. We with the help of technology trying to make sustainable and economical healthcare products.</Text>
</Box>

</Flex>
            </Box>
            <Box>
            <Flex gap={"20px"}>

<Box >
  <Heading textAlign={"start"} >Our Vision</Heading>
  <Text align={"justify"}>Our vision is to provide a healthy life for every human. We are visualizing world which is free of problems like pollution and disease. We at Revamp makes healthcare home appliances</Text>
</Box>

</Flex>
            </Box>
            <Box  >
            <Flex gap={"20px"}>
<Box>
  <Heading textAlign={"start"} >Installation & Uninstallation</Heading>
  <Text align={"justify"}>Buy the installation service at nearly NO Cost from here & get the satisfactory service at your doorsteps. Contact us immediately & be part of our happy customer.</Text>
</Box>

</Flex>
            </Box>
    </SimpleGrid>

{/* video --------------------------- */}


<Box display={"flex"} justifyContent={"center"} pb="20px">
  <Heading>How Water Purification Works ?</Heading>
</Box>

<Box
            as="video"
            width="100%"
            height="100%"
            _hover={{ all: "none" }}
            objectFit="inherit"
            autoPlay
            loop
            muted
            onEnded={(e) => {
              e.target.currentTime = 0;
              e.target.play();
            }}
          >
            <source src={`/animation.mp4`} type="video/mp4" />
          </Box>
       

       {/*------------------ slider -----------------------  */}
    <ContactForm/>




       
      </Box>


     
  
  );
};

export default Home;
