import {Box,  Flex,Link,  Image, SimpleGrid, Heading, Icon, Text } from '@chakra-ui/react';
import ModalData from './Model';
import Modaltwo from './Modaltwo';




const Spare = () => {

      
  return (
    <Box background="black" color={"white"} >

<Box>
      <Image src={`/Slider/sparepart.jpeg`}/>
    </Box>     
    </Box>
  );
};

export default Spare;



