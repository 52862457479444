import React from "react";
import { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";


const Slidertwo = () => {
  const [counter, setCounter] = useState(0); 

  const craouselData = [


      {
        url:`/Slider/six.png`
      },
     {
          url:`/Slider/e8.png`
        },{
          url:`/Slider/e9.jpg`
        },{
          url:`/Slider/e10.jpg`
        },{
          url:`/Slider/e13.jpeg`
        },{
          url:`/Slider/e16.jpeg`
        }
     
];


  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === craouselData.length - 1) {
        setCounter(-1);
      }setCounter((prev) => prev + 1);
    },3000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
   
        <Box position="relative" h={{base:"230px",md:"350px",lg:"650px"}}  mb="40px">
          <Image
            boxSize={"100%"}
            objectFit="fill"
            src={craouselData[counter].url}
            alt=""
            width={"100%"}
            className="darken"
          />
        </Box>
       
  );
};

export default Slidertwo;