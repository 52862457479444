import React from "react";
import { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";


const Slider = () => {
  const [counter, setCounter] = useState(0); 

  const craouselData = [
    {
      url:`/waterjetimage/1.jpg`
    },
    {
        url:`/waterjetimage/3.jpg`
      },{
        url:`/waterjetimage/4.jpg`
      },{
        url:`/waterjetimage/5.jpg`
      },{
        url:`/waterjetimage/6.jpg`
      },{
        url:`/waterjetimage/7.jpg`
      },{
        url:`/waterjetimage/8.jpg`
      }
     
];


  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === craouselData.length - 1) {
        setCounter(-1);
      }setCounter((prev) => prev + 1);
    },3000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
   
        <Box position="relative" h={{base:"150px",md:"350px",lg:"500px"}}>
          <Image
            boxSize={"100%"}
            objectFit="fill"
            src={craouselData[counter].url}
            alt=""
            width={"100%"}
            className="darken"
          />
        </Box>
       
  );
};

export default Slider;