import {Box,  Flex,Link,  Image, SimpleGrid, Heading, Icon, Text } from '@chakra-ui/react';
import { MdLocationOn, MdPhone, MdEmail } from 'react-icons/md';
import { ContactForm } from './Contactform';



const Contact = () => {


 

  return (
    <Box   pb="50px">

<Box        
            mt={{base:"-40px",lg:"-130px"}}
            as="video"
            width="100%"
            height="100%"
            _hover={{ all: "none" }}
            objectFit="inherit"
            autoPlay
            loop
            muted
            onEnded={(e) => {
              e.target.currentTime = 0;
              e.target.play();
            }}
          >
            <source src={`/contact.mp4`} type="video/mp4" />
          </Box>
         

  
   





    <SimpleGrid columns={[1,2,3]} m="auto"p="10px" mt="100px" gap="30px"  >
        <Box
      maxW="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="4"
      boxShadow="md"
      fontWeight={500}
    >
      <Heading as="h2" size="lg" mb="4" >
      Waterjet Water Purifier's
      </Heading>

      <Flex align="start" mb="2">
        <Icon as={MdLocationOn} boxSize="5" mr="2" />
        <Text align={"start"}>C-517/18 Hastal Road Near Sunar Market Uttam Nagar ND-110059</Text>
      </Flex>

      <Flex align="center" mb="2">
        <Icon as={MdPhone} boxSize="5" mr="2" />
        <Text>+91 7428578294</Text>
      </Flex>

      <Flex align="center" mb="2">
        <Icon as={MdEmail} boxSize="5" mr="2" />
        <Text>info@waterjetpurifier.com</Text>
      </Flex>
    </Box>
<Box ><Image mt="20px" src={`/logo512.png`}/>
<Box display={"flex"} justifyContent={"center"}>
<Link href="tel:7428578294" isExternal>
          <Icon as={MdPhone} boxSize="10" mr="2" ml="20px" />
        </Link>
        <Link href="mailto:info@waterjetpurifier.com" isExternal>
          <Icon as={MdEmail} boxSize="10" mr="2" ml="20px" />
        </Link>
        <Link href="tel:7428783127" isExternal>
          <Icon as={MdPhone} boxSize="10" mr="2" ml="20px" />
        </Link>
        </Box>

</Box>

    <Box
      maxW="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="4"
      boxShadow="md"
      fontWeight={500}
    >
      <Heading as="h2" size="lg" mb="4">
      Waterjet Contact No
      </Heading>
      <Link href="tel:7428783127" isExternal>
      <Flex align="center" mb="2">
        <Icon as={MdPhone} boxSize="5" mr="2" />
        <Text>+91 7428783127</Text>
      </Flex>
      </Link>

      <Link href="tel:7428578294" isExternal>
      <Flex align="center" mb="2">
        <Icon as={MdPhone} boxSize="5" mr="2" />
        <Text>+91 7428578294</Text>
      </Flex>
      </Link>
  

      <Link href="tel:7428578365" isExternal>
      <Flex align="center" mb="2">
        <Icon as={MdPhone} boxSize="5" mr="2" />
        <Text>+91 7428578365</Text>
      </Flex>
      </Link>

      <Link href="tel:7428576529" isExternal>
      <Flex align="center" mb="2">
        <Icon as={MdPhone} boxSize="5" mr="2" />
        <Text>+91 7428576529</Text>
      </Flex>
      </Link>
      
      <Link href="tel:7428757946" isExternal>
      <Flex align="center" mb="2">
        <Icon as={MdPhone} boxSize="5" mr="2" />
        <Text>+91 7428757946</Text>
      </Flex>
      </Link>

      
    </Box>


  
    </SimpleGrid>

    <ContactForm/>

    </Box>
  );
};

export default Contact;
